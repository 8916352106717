import React, { useEffect } from 'react';
import './Canvas.css';

export const Canvas = () => {
  useEffect(() => {
    var c = document.getElementById('canv');
    var $ = c.getContext('2d');


    var col = function(x, y, r, g, b) {
      $.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
      $.fillRect(x, y, 1,1);
    }
    var R = function(x, y, t) {
      return( Math.floor(192 + 64*Math.cos( (x*x-y*y)/3-11000 + t )) );
    }

    var G = function(x, y, t) {
      return( Math.floor(192 + 64*Math.sin( (x*x*Math.cos(t/4)+y*y*Math.sin(t/3))/200 ) ) );
    }

    var B = function(x, y, t) {
      return( Math.floor(192 + 128*Math.sin( 30*Math.sin(t/9) + ((x-200)*(x-200)+(y-200)*(y-200))/200) ));
    }

    var t = 0, x, y;

    var run = function() {
      for(x=0;x<=35;x++) {
        for(y=0;y<=35;y++) {
          col(x, y, R(x,y,t), G(x,y,t), B(x,y,t));
        }
      }
      t = t + 0.02;
      window.requestAnimationFrame(run);
    }

    run();
    console.log("loaded");
    }
  );

  return (
      <>
        <canvas id="canv" width="32" height="32" />
      </>
  );
}