import React from "react";

export default function Footer() {
    return (
        <>
           <div>
                <ul>
                    <li>
                        <p>Hello  from footer!!</p>
                    </li>
                </ul>
           </div>
        </>
    );
}