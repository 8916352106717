import { Carousel } from '../components/Carousel';
import App from '../App';
import slides from '../data/carouselData.json';
import { Hero } from '../components/Hero';
import { Canvas } from '../components/Canvas';

export default function Home() {
    return (
        <>
            <Canvas />
            <Hero />
            <Carousel data={ slides } />
            <App />
        </>
    );
}