import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import Layout from './Layout';
import { RouterProvider, Route, createBrowserRouter,
  createRoutesFromElements } from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(  
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />}/>
      <Route path="home" element={<Home />} />
      <Route path="gallery" element={<Gallery />} /> 
    </Route>

  )
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
