import React, { useEffect } from 'react';
import './Hero.css';

export const Hero = () => {
  return (
      <>
          
        <div className="hero">
          <div className="hero-border">
            <div className="hero-border-color">
            <img className="picture" src="/pictures/hero_cropped_2.jpg" alt="" />
          {/* <canvas id="canv" width="32" height="32" /> */}
          </div>
          </div>
        </div>

        <p className="hero-text">
          Börjer Börjersson - Skeppsmakaren i Skeppsta
        </p>
        {/* <p className="hero-text">
          - Skeppsmakaren i Skeppsta
        </p> */}
      </>
  );
}