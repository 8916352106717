import React from 'react';
import '../App.css';
import './Header.css';
import Home from '../pages/Home';
import Gallery from '../pages/Gallery';
import { NavLink } from 'react-router-dom';

function Header() {
  return (
    <div className="sticky-header">
      <nav>
        <ul id="primary-navigation">
          <NavLink to="home" element={<Home />} className={({isActive}) => 
          `${isActive ? "isActiveColor" : "notActiveColor"}`}>
            <span aria-hidden="true">Hem</span>
          </NavLink>
          <NavLink to="gallery" element={<Gallery />} className={({isActive}) => 
          `${isActive ? "isActiveColor" : "notActiveColor"}`}>
            <span aria-hidden="true">Galleri</span>
          </NavLink>
        </ul>
    
      </nav>
    </div>
    
  );
}

export default Header;
