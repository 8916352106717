import React from "react";
import "./Carousel.css";


export const Carousel = ({ data }) => {
	console.log(data.slides);
	return (
	<>
	<div className="chop-container">
		<img src="pictures/karusell-chops/chop1.jpg" alt="hej" />
		<img src="pictures/karusell-chops/chop2.jpg" alt="hej" />
	</div>
	</>
	)};

